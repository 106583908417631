import { Component, Input } from '@angular/core';
import { MenuGroup } from 'src/app/_models/sidemenu/menugroup';
import { Employer } from 'src/app/_models/employer.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
    selector: 'menu-group',
    templateUrl: './menugroup.html',
    styleUrls: ['./menugroup.scss']
})
export class MenuGroupComponent {
    @Input()  menuGroup: MenuGroup;

    employer: Employer;

    constructor(authenticationService: AuthenticationService) {
        authenticationService.employer.subscribe(employer => this.employer = employer);
    }
}