import { Injectable } from "@angular/core";
import { Subject, Observable } from 'rxjs';

@Injectable()
export class TopbarMenuService {
    private titleSubject = new Subject<String>();

    changeTitle(title: String): void {
        this.titleSubject.next(title);
    }

    getTitle(): Observable<String> {
        return this.titleSubject.asObservable();
    }
}