import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
    selector: 'topmenu',
    templateUrl: './topmenu.html',
    styleUrls: ['./topmenu.scss']
})
export class TopMenuComponent {

    token: any;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.token.subscribe(x => this.token = x);
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
      }
}