import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/AuthGuard';

const routes: Routes = [
  { path: '', loadChildren: './home/home.module#HomeModule', canActivate: [AuthGuard] },
  { path: 'login', loadChildren: './login/login.module#LoginModule' },
  { path: 'decisionlist', loadChildren: './decisionlist/decisionlist.module#DecisionListModule', canActivate: [AuthGuard] },
  { path: 'marketingrules', loadChildren: './marketingrules/marketingrules.module#MarketingRulesModule', canActivate: [AuthGuard] },
  { path: 'smstemplates', loadChildren: './smstemplates/smstemplates.module#SmsTemplatesModule', canActivate: [AuthGuard] },
  { path: 'emailtemplates', loadChildren: './emailtemplates/emailtemplates.module#EmailTemplatesModule', canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
