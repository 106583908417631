import { Component, Input } from '@angular/core';
import { MenuItem } from 'src/app/_models/sidemenu/menuitem';

@Component({
    selector: 'menu-item',
    templateUrl: './menuitem.html',
    styleUrls: ['./menuitem.scss']
})
export class MenuItemComponent {
    @Input()  menuItem: MenuItem;
}