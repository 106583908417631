import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Employer } from '../_models/employer.model';
import { EmployerService } from './employer.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private tokenSubject: BehaviorSubject<String>;
    public token: Observable<String>;
    private employerSubject: BehaviorSubject<Employer>;
    public employer: Observable<Employer>;
    private permissionsSubject: BehaviorSubject<any>;
    public permissions: Observable<any>;

    constructor(
        private http: HttpClient,
        private employerService: EmployerService
    ) {
        this.tokenSubject = new BehaviorSubject<String>(localStorage.getItem('token'));
        this.token = this.tokenSubject.asObservable();
        let empl = new Employer();
        this.employerSubject = new BehaviorSubject<Employer>(Object.assign(empl, JSON.parse(localStorage.getItem('employer'))));
        this.employer = this.employerSubject.asObservable();
        let perms = {};
        if (!!empl.permissions) {
            empl.permissions.forEach(permission => {
                perms[''+permission] = true;
            });
        }
        this.permissionsSubject = new BehaviorSubject<any>(perms);
        this.permissions = this.permissionsSubject.asObservable();
    }

    public get tokenValue() {
        return this.tokenSubject.value;
    }

    login(username: String, password: String) {
        return this.http.post<any>(`${environment.config.apiUrl}/login`, {username: username, password: password}, { observe: 'response' })
            .pipe(map(resp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                let token = resp.headers.get('Authorization').substring(7);
                localStorage.setItem('token', token);
                this.tokenSubject.next(token);

                localStorage.setItem('employer', JSON.stringify(resp.body));
                this.employerSubject.next(resp.body);

                let perms = {};
                resp.body.permissions.forEach(permission => {
                    perms[''+permission] = true;
                });
                    
                this.permissionsSubject.next(perms);
                
                return resp;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('token');
        this.tokenSubject.next(null);
        localStorage.removeItem('employer');
        this.employerSubject.next(null);
        this.permissionsSubject.next({});
    }

    updatePermissions() {
        this.employerService.getEmployer().subscribe(employer => {
            localStorage.setItem('employer', JSON.stringify(employer));
            this.employerSubject.next(employer);

            let perms = {};
            employer.permissions.forEach(permission => {
                perms[''+permission] = true;
            }); 
            
            this.permissionsSubject.next(perms);
        });
    }
}