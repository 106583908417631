export class Employer {
    employer_id: number;
    firstname: string
    lastname: string;
    middlename: string;
    login: string;
    email: string;
    pin: string;
    vcc_id_extension: number;
    status_id: number;
    created: Date;
    author: number;
    parent_id: number;
    permissions: string[];
}