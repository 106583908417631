import { Component, OnInit } from '@angular/core';
import { MenuGroup } from 'src/app/_models/sidemenu/menugroup';
import { Employer } from 'src/app/_models/employer.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LocalizationService } from 'src/app/_services/localization.service';

@Component({
    selector: 'sidemenu',
    templateUrl: './sidemenu.html',
    styleUrls: ['./sidemenu.scss']
})
export class SideMenuComponent implements OnInit {
    menuGroups: MenuGroup[];
    employer: Employer;
    permissions;

    constructor(
        private authenticationService: AuthenticationService,
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {

        this.authenticationService.employer.subscribe(employer => this.employer = employer);
        this.authenticationService.permissions.subscribe(permissions => {
            this.permissions = permissions;
            this.localizationService.getLocalization('sidemenu/sidemenu').then(localization => {
                this.menuGroups = [{
                        items:[
                            {icon: 'fa fa-fw fa-home', name: localization.home, url: ''}
                        ]
                    }];
                let administration = {
                    name: localization.administration,
                    items:[]
                };

                if (!!permissions) {
                    if (this.permissions['SMS_TEMPLATE_VIEW']) {
                        administration.items.push({icon: 'fa fa-mobile', name: localization.smstemplates, url: '/smstemplates'});
                    }
                    if (this.permissions['EMAIL_TEMPLATE_VIEW']) {
                        administration.items.push({icon: 'fa fa-envelope-o', name: localization.emailtemplates, url: '/emailtemplates'});
                    }
                    if (administration.items.length > 0) {
                        this.menuGroups.push(administration)
                    }
                }
            });
            
                
            
            
            
                /*
                        {icon: 'fa fa-share-alt', name: 'Marketing rules', url: '/decisionlist', roles:['Marketing decision', 'Head Office', 'Administrator']},
*/
            
        });
        
        
    }
    
}