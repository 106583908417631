import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class LocalizationService {
    constructor(private http: HttpClient) { }

    getLocalization(componentName: String): Promise<any> {
        return this.http.get<any>(`assets/localization/${environment.locale}/${componentName}.json`)
            .toPromise()
            .then(res => res)
            .then(data => { return data; });
    }
}