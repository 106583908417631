import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideMenuComponent } from './menu/sidemenu/sidemenu';
import { MenuGroupComponent } from './menu/menugroup/menugroup';
import { MenuItemComponent } from './menu/menuitem/menuitem';
import { TopMenuComponent } from './menu/topmenu/topmenu';
import { ToastModule } from 'primeng/toast';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { MessageService } from 'primeng/components/common/messageservice';
import { TopbarMenuService } from './_services/topbarmenu.service';
import { LocalizationService } from './_services/localization.service';
import { EmployerService } from './_services/employer.service';

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent,
    MenuGroupComponent,
    MenuItemComponent,
    TopMenuComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MessageService,
    TopbarMenuService,
    LocalizationService,
    EmployerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
