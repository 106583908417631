import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class EmployerService {
    constructor(private http: HttpClient) {}

    getEmployer() :Observable<any> {
        return this.http.get<any>(`${environment.config.apiUrl}/employer`);
    }
}