import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    private permissions = {};

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.permissions.subscribe(permissions => this.permissions = permissions);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = this.authenticationService.tokenValue;
        if (token) {
            let url = route.routeConfig.path;
            if (url === 'smstemplates' && !this.permissions['SMS_TEMPLATE_VIEW']) {
                return false;
            }
            if (url === 'emailtemplates' && !this.permissions['EMAIL_TEMPLATE_VIEW']) {
                return false;
            }
            return true; 
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}