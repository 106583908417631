import { Component } from '@angular/core';
import { AuthenticationService } from './_services/authentication.service';
import { TopbarMenuService } from './_services/topbarmenu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  
})
export class AppComponent {
  menuActive: boolean;
  title: String;
  currentUser: any;

  constructor(private authenticationService: AuthenticationService,
              private topbarMenuService: TopbarMenuService) {
    this.authenticationService.token.subscribe(x => this.currentUser = x);
    this.topbarMenuService.getTitle().subscribe(title => this.title = title);
  }

  onMenuButtonClick(event: Event) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }

  get environment() { return environment; }
}
